
























































import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component
export default class Forgotten extends Vue {
    private email: string = '';
    private message: string = '';
    private loading: boolean = false;
    private success: boolean = false;
    private error: boolean = false;

    private sendResetRequest(): void {
        this.loading = true;
        this.success = false;
        this.error = false;
        axios.post('https://bundk-api.bdrops.space/api/forgotten', { email: this.email }).then(() => {
            this.success = true;
            this.message = 'Sofern ein Benutzerkonto mit der angegebenen E-Mail Adresse existiert, haben wir dorthin einen Link zum Zurücksetzen des Passworts versendet. Überprüfen Sie bitte auch Ihren Spam-Ordner!';
        }, () => {
            this.error = true;
            this.message = 'Leider gab es ein Problem beim Absenden Ihrer Anfrage. Bitte versuchen Sie es später noch einmal.';
        }).then(() => {
            this.loading = false;
        });
    }
}
